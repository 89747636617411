import React, { useEffect, useState } from "react";
import NavHyperLink from "../../components/NavHyperLink";
import { Button, Table, Pagination, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import { APIInsertDataLog } from "../../types/APIInsertDataLog";
import {
  getSystemAPIInsertDataLogListApi,
  downloadSystemAPIInsertDataLogFileApi,
} from "../../api/index";
import viewUtils from "../../utils/viewUtils";

const APIInsertDataLogPage = () => {
  const [datalist, setDatalist] = useState<{
    data: APIInsertDataLog[];
    total: number;
  }>({
    data: [],
    total: 0,
  });
  const [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
  });
  const handlePageChange = (page: number) =>
    setFilter((data) => ({ ...data, page: page }));

  useEffect(() => {
    getSystemAPIInsertDataLogListApi({
      page: filter.page,
      page_size: filter.page_size,
    }).then((res) => {
      if (res.success) {
        setDatalist({
          data: res.data,
          total: res.pagination?.count || 0,
        });
      }
    });
  }, [filter]);

  const handleDownload = (id?: number) => {
    downloadSystemAPIInsertDataLogFileApi(id ?? 0)
      .then((res: any) => {
        const href = window.URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", res.headers["file-name"]);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((e) => {
        message.error("下载日志失败");
      });
  };

  const columns: ColumnsType<APIInsertDataLog> = [
    {
      key: "dataset",
      title: "数据集名称",
      render: (d: APIInsertDataLog) => d.version?.dataset_data?.name,
    },
    {
      key: "version",
      title: "版本名称",
      render: (d: APIInsertDataLog) => d.version?.name,
    },
    {
      key: "table",
      title: "表名称",
      render: (d: APIInsertDataLog) => d.table?.name,
    },
    {
      key: "created_at",
      title: "接口时间",
      render: (d: APIInsertDataLog) => viewUtils.prettifyDatatime(d.created_at),
    },
    {
      key: "actions",
      title: "操作",
      render: (d: APIInsertDataLog) => (
        <div style={{ display: "flex" }}>
          <Button size="small" type="link" onClick={() => handleDownload(d.id)}>
            下载日志
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <NavHyperLink items={[{ title: "接口数据记录" }]} />
      </div>
      <div style={{ marginTop: "1.5em" }}>
        <Table
          className="common-table"
          rowKey={(r) => r.id || ""}
          scroll={{ x: 700, scrollToFirstRowOnChange: true }}
          columns={columns}
          dataSource={datalist.data}
          pagination={false}
        />
        <Pagination
          showQuickJumper
          hideOnSinglePage={!datalist.data?.length}
          showTotal={(total) => `共${total}条`}
          current={filter.page}
          pageSize={filter.page_size}
          total={datalist.total}
          onChange={handlePageChange}
          style={{
            textAlign: "right",
            justifyContent: "flex-end",
            marginTop: "1em",
          }}
        />
      </div>
    </>
  );
};

export default APIInsertDataLogPage;
