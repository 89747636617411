import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
} from "react";
import { Table, TableField } from "../types/Table";
import { useTranslation } from "react-i18next";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  Modal,
  Select,
  Steps,
  Upload,
  Space,
  Checkbox,
  Row,
  Col,
  Typography,
  message,
  Button,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { InboxOutlined } from "@ant-design/icons";
import { fileToWorkBook } from "../utils/SheetUtils";
import { UploadChangeParam } from "antd/es/upload";
import { WorkBook } from "xlsx";
import * as XLSX from "xlsx";
import { toDate, getFormatDate_XLSX } from "../utils/TypeUtils";
import GlobalLoader from "../components/GlobalLoading";

const Window: any = window;

const TableImportModal = forwardRef(
  (
    props: {
      submitCallback?: (data: any) => void;
      handleOpenBatchImport?: () => void;
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [showItem, setShowItem] = useState<Table | undefined>();
    const [workbook, setWorkbook] = React.useState<WorkBook | undefined>();
    const [selectedSheet, setSelectSheet] = useState<number | undefined>();
    const [fieldsMap, setFieldsMap] = useState<Map<string, string>>(
      new Map<string, string>()
    );
    const [selectedFields, setSelectedFields] = useState<any[]>([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const calSourceFields = () => {
      let sourceFields: string[] = [];
      try {
        if (workbook && selectedSheet !== undefined) {
          const worksheet: any =
            workbook?.Sheets[workbook.SheetNames[selectedSheet]];
          const range = XLSX.utils.decode_range(worksheet["!ref"]);
          for (let C = range.s.c; C <= range.e.c; C++) {
            const cell = worksheet[XLSX.utils.encode_cell({ c: C, r: 0 })];
            sourceFields.push(cell.v);
          }
        }
      } catch (e) {
        console.log(e);
      }
      return sourceFields;
    };

    useEffect(() => {
      if (workbook) {
        const sheet = Window.luckysheet.getSheet();
        if (!!sheet) {
          try {
            if (workbook.SheetNames.length === 1) {
              setSelectSheet(0);
            } else {
              const index = workbook.SheetNames.indexOf(sheet.name);
              if (index >= 0) {
                setSelectSheet(index);
              }
            }
          } catch (e) {}
        }
      }
    }, [workbook]);

    const handleCheckChange = (value: any[]) => {
      setSelectedFields(value);
      setIndeterminate(
        !!value.length && value.length < (showItem?.meta?.fields || []).length
      );
      setCheckAll(value.length === (showItem?.meta?.fields || []).length);
    };

    useEffect(() => {
      let map = new Map<string, string>();
      try {
        const sheet = Window.luckysheet.getSheet();
        if (!!sheet && selectedSheet !== undefined) {
          const sourceFields = calSourceFields();
          if (sourceFields && sourceFields.length > 0) {
            sourceFields.forEach((column: string) => {
              let field = sheet.originTableData?.meta?.fields?.find(
                (field: TableField) => field.name === column
              );
              if (field) {
                map.set(field.identifier!, column);
              }
            });
          } else {
            new Error("fields null");
          }
        } else {
          new Error("sheet null");
        }
      } catch (e) {}
      setFieldsMap(map);
      handleCheckChange(Array.from(map.keys()));
      // eslint-disable-next-line
    }, [selectedSheet]);

    useImperativeHandle(ref, () => ({
      show: (item: Table) => {
        setWorkbook(undefined);
        setSelectSheet(undefined);
        setFieldsMap(new Map<string, string>());
        setSelectedFields([]);
        setIndeterminate(false);
        setCheckAll(false);
        setShowItem(item);
      },
      close: handleCancel,
    }));

    const handleCancel = () => {
      setWorkbook(undefined);
      setSelectSheet(undefined);
      setShowItem(undefined);
      setFieldsMap(new Map<string, string>());
      setSelectedFields([]);
      setIndeterminate(false);
      setCheckAll(false);
    };

    const handleSubmit = () => {
      let data: any[] = [];
      if (!workbook) {
        message.error(t("table.import.upload_blank"));
        return;
      }
      if (selectedSheet === undefined) {
        message.error(t("table.import.table_blank"));
        return;
      }
      if (selectedFields.length < 1) {
        message.error(t("table.import.field_blank"));
        return;
      }
      if (
        selectedFields.filter((item: string) => !fieldsMap.get(item)).length > 0
      ) {
        message.error(t("table.import.match_blank"));
        return;
      }
      let currentSheet: XLSX.WorkSheet | null = null;
      try {
        currentSheet =
          workbook?.Sheets[workbook.SheetNames[selectedSheet || 0]];
      } catch (e) {
        console.log(e);
      }
      if (!currentSheet) {
        message.error(t("table.import.table_blank"));
        return;
      }
      const sheetData = XLSX.utils.sheet_to_json(currentSheet, {
        defval: "",
        raw: false,
      });
      const tableFields = showItem?.meta?.fields || [];
      for (let r = 0, len = sheetData.length; r < len; r++) {
        let newRow: any = {};
        let rowData: any = sheetData[r];
        for (let c = 0, clen = tableFields.length; c < clen; c++) {
          const field = tableFields[c];
          const sheetfield = fieldsMap.get(field.identifier || "");
          if (sheetfield && selectedFields.includes(field.identifier || "")) {
            if (field.type === "DATE") {
              const value = getFormatDate_XLSX(rowData[sheetfield]);
              newRow[field.identifier || ""] = !!value.getTime()
                ? toDate(value)
                : rowData[sheetfield];
            } else {
              let value = rowData[sheetfield];
              if (typeof value === "string") {
                value = value.trim();
              }
              newRow[field.identifier || ""] = value;
            }
          }
        }
        data.push(newRow);
      }
      props.submitCallback && props.submitCallback(data);
      handleCancel();
    };

    const FileUploader = () => {
      const handleUploadResult = (info: UploadChangeParam) => {
        const file: File | undefined = (info.fileList[0] || {})?.originFileObj;
        if (file) {
          try {
            fileToWorkBook(file).then((workbook) => {
              setWorkbook(workbook);
              GlobalLoader.hide();
            });
          } catch (e: any) {
            console.log(e.message);
            GlobalLoader.hide();
          }
        } else {
          GlobalLoader.hide();
        }
      };

      return (
        <>
          <Upload.Dragger
            className="hide-file"
            name="file"
            accept=".xlsx, .xls, .csv"
            multiple={false}
            maxCount={1}
            beforeUpload={() => {
              GlobalLoader.show();
              return false;
            }}
            onChange={handleUploadResult}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t("table.import.upload")}</p>
            <p className="ant-upload-hint">{t("table.import.upload_format")}</p>
          </Upload.Dragger>
          {showItem?.table_type === "COMMON" && (
            <Button
              type="link"
              style={{ marginTop: "1em", padding: 0 }}
              onClick={() => {
                if (props.handleOpenBatchImport) {
                  props.handleOpenBatchImport();
                  handleCancel();
                }
              }}
            >
              {t("dataset.actions.batch_import")}
            </Button>
          )}
        </>
      );
    };

    const FieldsMapper = () => {
      const sourceFields = calSourceFields();

      const onCheckAllChange = (e: CheckboxChangeEvent) => {
        setSelectedFields(
          e.target.checked
            ? (showItem?.meta?.fields || []).map(
                (field: TableField) => field.identifier
              )
            : []
        );
        setIndeterminate(false);
        setCheckAll(e.target.checked);
      };

      return (
        <>
          <div
            hidden={!workbook || workbook.SheetNames.length === 1}
            style={{ marginBottom: "1em" }}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <Typography.Text>{t("table.import.mult_table")}:</Typography.Text>
              <Select
                style={{ width: "100%" }}
                showSearch={true}
                placeholder={t("table.import.table_blank")}
                value={selectedSheet}
                onChange={(index) => setSelectSheet(index)}
                options={(workbook?.SheetNames || []).map(
                  (sheetName, index) => ({
                    value: index,
                    label: sheetName,
                  })
                )}
              />
            </Space>
          </div>
          <Row
            align="middle"
            gutter={[8, 8]}
            style={{ width: "100%", textAlign: "center" }}
          >
            <Col span={2}>
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              />
            </Col>
            <Col span={10}>
              <Typography.Text strong>
                {t("table.import.target_field")}
              </Typography.Text>
            </Col>
            <Col span={2}>
              <ArrowLeftOutlined />
            </Col>
            <Col span={10}>
              <Typography.Text strong>
                {t("table.import.source_field")}
              </Typography.Text>
            </Col>
          </Row>
          <Checkbox.Group
            style={{ width: "100%", marginTop: "1em" }}
            value={selectedFields}
            onChange={handleCheckChange}
          >
            <Row
              align="middle"
              gutter={[8, 8]}
              style={{ width: "100%", textAlign: "center" }}
            >
              {(showItem?.meta?.fields || []).map((field: TableField) => (
                <React.Fragment key={field.identifier}>
                  <Col span={2}>
                    <Checkbox value={field.identifier} />
                  </Col>
                  <Col span={10}>{field.name}</Col>
                  <Col span={2}>
                    <ArrowLeftOutlined />
                  </Col>
                  <Col span={10}>
                    <Select
                      allowClear={true}
                      style={{ width: "100%" }}
                      onChange={(sourceField) => {
                        setFieldsMap((prev) => {
                          const newMap = new Map(prev);
                          newMap.forEach((value, key) => {
                            if (value === sourceField) {
                              newMap.delete(key);
                            }
                          });
                          newMap.set(field.identifier!, sourceField);
                          return newMap;
                        });
                      }}
                      options={sourceFields
                        .filter((fieldName) => {
                          const selectedSourceField = fieldsMap.get(
                            field.identifier!
                          );
                          return (
                            fieldName === selectedSourceField ||
                            !Array.from(fieldsMap.values()).includes(fieldName)
                          );
                        })
                        .map((fieldName) => {
                          return {
                            key: fieldName,
                            label: fieldName,
                            value: fieldName,
                          } as any;
                        })}
                      value={fieldsMap.get(field.identifier!)}
                    />
                  </Col>
                </React.Fragment>
              ))}
            </Row>
          </Checkbox.Group>
        </>
      );
    };

    const handleGoBack = () => {
      setWorkbook(undefined);
      setSelectSheet(undefined);
      setFieldsMap(new Map<string, string>());
      setSelectedFields([]);
      setIndeterminate(false);
      setCheckAll(false);
    };

    return (
      <Modal
        centered
        destroyOnClose
        title={t("table.import.title")}
        open={!!showItem}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            {t("common.cancel")}
          </Button>,
          ...(!!workbook
            ? [
                <Button key="previous" onClick={handleGoBack}>
                  {t("common.step.previous")}
                </Button>,
              ]
            : []),
          <Button key="submit" type="primary" onClick={handleSubmit}>
            {t("common.import")}
          </Button>,
        ]}
      >
        <Steps
          size="small"
          type="navigation"
          current={!!workbook ? 1 : 0}
          items={[
            { title: t("table.import.upload") },
            { title: t("table.import.match_field") },
          ]}
        />
        <div style={{ marginBottom: "2em" }} />
        {!!workbook ? <FieldsMapper /> : <FileUploader />}
      </Modal>
    );
  }
);

export default TableImportModal;
